<template>
    <form novalidate class="question-form" @submit.prevent="submit">
        <div class="form-group">
            <input
                    v-model="contactName"
                    type="text"
                    class="form-control form-control-light"
                    :class="{'is-invalid': $v.contactName.$error}"
                    placeholder="Ваше имя*"
            >
            <div
                    v-if="$v.contactName.$dirty && !$v.contactName.required"
                    class="invalid-feedback"
            >
                Поле обязательно для заполнения
            </div>
            <div
                    v-if="$v.contactName.$dirty && !$v.contactName.maxLength"
                    class="invalid-feedback"
            >
                Поле должно содержать не более {{$v.contactName.$params.maxLength.max}} символов
            </div>
        </div>
        <div class="form-group">
            <input
                    v-model="phone"
                    type="text"
                    class="form-control form-control-light"
                    :class="{'is-invalid': $v.phone.$error}"
                    placeholder="+375 (__) ___-__-__*"
                    v-mask="{
                    mask: '+375 (99) 999-99-99',
                    placeholder: '_',
                    showMaskOnHover: false
                }"
                    @blur="validatePhone"
            >
            <div
                    v-if="$v.phone.$dirty && !$v.phone.required"
                    class="invalid-feedback"
            >
                Поле обязательно для заполнения
            </div>
            <div
                    v-if="showPhoneError"
                    class="invalid-feedback"
            >
                Некорректный номер телефона
            </div>
        </div>
        <button
                class="btn"
                type="submit"
                :disabled="isRequesting"
        >Заказать звонок</button>
    </form>
</template>

<script>
    import {maxLength, required} from 'vuelidate/lib/validators'
    import api from "@/application"

    export default {
        name: "QuestionForm",
        data() {
            return {
                contactName: null,
                phone: null,
                hasPhoneError: false,
                showPhoneError: false,
                isRequesting: false,
            }
        },
        validations: {
            contactName: {required, maxLength: maxLength(50)},
            phone: {required}
        },
        methods: {
            validatePhone(field) {
                if (!field.target.inputmask.isComplete() && field.target.value !== '') {
                    this.hasPhoneError = true
                    return
                }
                if (field.target.value === '' || field.target.inputmask.isComplete()) {
                    this.showPhoneError = false
                }
                this.hasPhoneError = false
            },
            async submit() {
                this.showPhoneError = this.hasPhoneError
                if (this.$v.$invalid || this.showPhoneError) {
                    this.$v.$touch()
                    return
                }

                this.isRequesting = true
                try {
                    await api.mail.sendMessageOnOrderedCall({
                        contactName: this.contactName,
                        phone: this.phone
                    })
                    this.isRequesting = false
                    this.contactName = null
                    this.phone = null
                    this.$toast.success('Спасибо! Мы свяжемся с Вами в ближайшее время!')
                } catch (e) {
                    this.isRequesting = false
                    this.$toast.error(e.message)
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .question-form {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 900px;

        .form-group {
            flex-shrink: 1;
            flex-grow: 1;
            margin-right: 24px;

            .form-control {
                height: 48px;
                font-size: 16px;
            }
        }

        .btn {
            flex-shrink: 0;
            flex-grow: 0;
        }
    }

    @media (max-width: 620px) {
        .question-form {
            flex-direction: column;
            max-width: 320px;

            .form-group {
                margin-right: 0;
            }
        }
    }
</style>
