<template>
    <div class="col-sm-6">
        <div class="advantage">
            <div class="advantage-header">
                <img :src="require(`../../assets/img/${advantage.icon}`)" alt="" class="advantage-icon">
                <h3 class="advantage-title">{{advantage.title}}</h3>
            </div>
            <p class="advantage-text">{{advantage.text}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Advantage",
        props: ['advantage']
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';
    .col-sm-6 {
        margin-bottom: 32px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .advantage {
        border-radius: @br;
        padding: 32px 24px 24px;
        height: 100%;
        background-color: @bg-color;
        &-header {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            margin-bottom: 24px;
        }
        &-icon {
            flex-shrink: 0;
            display: block;
            border: none;
            margin-right: 12px;
            width: 40px;
            height: 40px;
        }
        &-title {
            margin: 0;
            padding-bottom: 4px;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.3px;
            color: #ffffff;
        }
        &-text {
            font-size: 13px;
            line-height: 1.8;
            letter-spacing: 0.3px;
            color: @text-color-light;
        }
    }
    @media (max-width: 575px) {
        .col-sm-6 {
            margin-bottom: 24px;
        }
    }
    @media (max-width: 380px) {
        .advantage {
            padding: 24px 16px 16px;
            &-header {
                margin-bottom: 16px;
            }
        }
    }
</style>
