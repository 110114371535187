<template>
    <div class="home-wrapper">
        <header class="header">
            <div class="container">
                <img class="header-logo" src="../assets/img/logo.png" alt="" width="113" height="54">
                <p class="header-organization-name">{{username}}</p>
                <button class="btn" type="button" @click.stop="clickLogIn">Войти в систему</button>
            </div>
        </header>
        <section class="welcome">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <div class="welcome-content">
                            <h2 class="title">Онлайн-сервис <br>по&nbsp;поиску <span>тендеров</span></h2>
                            <p>Все тендеры Беларуси на одном сайте</p>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <AdvantagesWrapper></AdvantagesWrapper>
                    </div>
                </div>
            </div>
        </section>
        <section class="opportunities">
            <div class="container">
                <div class="row align-content-center">
                    <div class="col-lg-5 order-lg-2">
                        <div class="opportunities-content">
                            <h2 class="title">Возможности <span>сервиса</span></h2>
                            <p>На Bytorg собраны все тендеры и закупки в Беларуси.
                                Вам достаточно всего один раз создать точный поисковый фильтр из неограниченного количества параметров: ключевые слова и фразы, место нахождения Заказчика или место поставки, код ОКРБ и бюджет закупки. Можете добавить слова-исключения и прочее. И вот перед Вами предложения со всей Беларуси с активными ссылками на Заказчиков. Система покажет актуальные и завершенные закупки, а также количество дней до окончания подачи предложения.
                                Сохраняйте нужное в «Избранное», лишнее — скрывайте.
                                Один аккаунт — любое количество пользователей.</p>
                            <button class="btn btn-light" type="button" @click.stop="openRegistrationModal">Попробовать бесплатно</button>
                        </div>
                    </div>
                    <div class="col-lg-7 order-lg-1">
                        <div class="video-wrapper">
                            <iframe
                                    src="https://www.youtube.com/embed/OLPZZ0BWe8U"
                                    style="width: 100%;height: 100%"
                                    allowfullscreen
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="questions">
            <div class="container">
                <h2 class="title">Остались <span>вопросы</span></h2>
                <p>Закажите обратный звонок, и мы свяжемся с вами в течение рабочего дня, чтобы ответить на все возникшие вопросы.</p>
                <QuestionForm></QuestionForm>
            </div>
        </section>
        <footer class="footer">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <img src="../assets/img/logo.png" alt="" class="footer-logo" width="113" height="54">
                    </div>
                    <div class="col-lg-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <router-link
                                        :to="privacyPolicyPath"
                                        exact
                                        class="footer-link"
                                >
                                    <span>Политика конфиденциальности</span>
                                    <ico name="icoOpenInNew" color="#79919C"></ico>
                                </router-link>
                            </div>
                            <div class="col-sm-6">
                                <router-link
                                        :to="termsOfUsePath"
                                        exact
                                        class="footer-link"
                                >
                                    <span>Правила пользования</span>
                                    <ico name="icoOpenInNew" color="#79919C"></ico>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-xl-4 order-xl-2">
                        <div class="footer-contacts">
                            <div class="footer-contacts-item">
                                <p class="footer-contacts-item-title">Телефон</p>
                                <a class="footer-contacts-item-link" href="tel:+375447228833">+375447228833</a>
                            </div>
                            <div class="footer-contacts-item">
                                <p class="footer-contacts-item-title">Вопросы и предложения</p>
                                <a class="footer-contacts-item-link" href="mailto:office@bytorg.by">office@bytorg.by</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 order-xl-1">
                        <p class="footer-copyright">© ООО “Байторг Бай”, 2024</p>
                        <p class="footer-copyright">Копирование материалов запрещено. При согласованном использовании материалов сайта необходима ссылка на ресурс.</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import LoginModal from '@/components/homePage/LoginModal'
    import RegistrationModal from '@/components/homePage/RegistrationModal'
    import AdvantagesWrapper from '@/components/homePage/AdvantagesWrapper'
    import QuestionForm from '@/components/homePage/QuestionForm'
    import {mapGetters} from "vuex"
    import {purchasesPath} from "@/router/purchases"
    import {LoginModalProperties, RegistrationModalProperties} from '@/utils/modalsProperties'
    import {termsOfUsePath} from "@/router/termsOfUse"
    import {privacyPolicyPath} from "@/router/privacyPolicy"

    export default {
        name: 'Home',
        data() {
            return {
                termsOfUsePath,
                privacyPolicyPath,
            }
        },
        components: {
            AdvantagesWrapper,
            QuestionForm
        },
        computed: {
            ...mapGetters('auth', ['isAuthenticated', 'email']),
            ...mapGetters('organization', ['shortNameOrganization', 'organizationId']),
            username() {
                return this.shortNameOrganization ?? this.email
            }
        },
        methods: {
            openLoginModal() {
                this.$modal.show(
                    LoginModal,
                    null,
                    LoginModalProperties
                );
            },
            openRegistrationModal() {
                this.$modal.show(
                    RegistrationModal,
                    null,
                    RegistrationModalProperties
                )
            },
            clickLogIn() {
                this.isAuthenticated ? this.$router.push(purchasesPath) : this.openLoginModal()
            },
        },
    }
</script>

<style lang="less" scoped>
    @import '../assets/less/variables.less';
    @import '../assets/less/bootstrapGrid.less';
    @import '../assets/less/commonStyles.less';
    /*@import '../assets/less/homeStyles.less';*/
    /*body.no-scroll {*/
    /*    .home {*/
    /*        padding-right: 17px;*/
    /*    }*/
    /*    .header {*/
    /*        right: 17px;*/
    /*    }*/
    /*}*/
    .header {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        z-index: 1;
        padding-top: 40px;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-organization-name {
            margin-left: auto;
            margin-right: 16px;
            padding-left: 32px;
            font-weight: 500;
            text-align: right;
        }
        .btn {
            flex-shrink: 0;
        }
    }
    .title {
        margin-top: 0;
        margin-bottom: 24px;
        font-weight: 500;
        font-size: 48px;
        line-height: 1.18;
        letter-spacing: 0.92px;
        text-align: left;
        span {
            color: @text-color-light;
        }
    }
    .welcome {
        position: relative;
        padding-top: 228px;
        padding-bottom: 96px;
        background-image: url("../assets/img/bg-home-welcome.png");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            height: 186px;
            background: linear-gradient(180deg, transparent 0%, @bg-color-light 100%);
        }
        .container {
            position: relative;
            z-index: 2;
        }
        &-content {
            margin-bottom: 32px;
            padding-right: 32px;
        }
    }
    .opportunities {
        overflow: hidden;
        padding: 144px 0;
        &-content {
            position: relative;
            padding-left: 32px;
            &::before {
                content: '';
                position: absolute;
                top: 16px;
                left: 147px;
                z-index: 1;
                width: 445px;
                height: 445px;
                background-image: url("../assets/img/bg-dots.svg");
                background-repeat: no-repeat;
            }
            .title {
                position: relative;
                z-index: 2;
            }
            p {
                position: relative;
                z-index: 2;
                margin-bottom: 24px;
                line-height: 1.7;
            }
            .btn {
                position: relative;
                z-index: 2;
            }
        }
    }
    .video-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-width: 720px;
        text-align: center;
        color: #ffffff;
        background-color: @bg-color;
        box-shadow: 0 8px 12px 0 rgba(0,0,0,0.08), 0 16px 48px 0 rgba(0,0,0,0.12);
    }
    .questions {
        padding: 56px 0;
        .title {
            margin-bottom: 48px;
            text-align: center;
        }
        p {
            margin-bottom: 48px;
            text-align: center;
        }
    }

    @media (max-width: 1368px) {
        .welcome {
            padding-top: 160px;
            padding-bottom: 56px;
        }
    }

    @media (max-width: 1260px) {
        .title {
            font-size: 40px;
        }
    }

    @media (max-width: 991px) {
        .header {
            padding-top: 16px;
        }
        .title {
            text-align: center;
        }
        .welcome {
            padding-top: 128px;
            padding-bottom: 24px;
            &-content {
                margin-bottom: 40px;
                padding-right: 0;
                p {
                    text-align: center;
                }
            }
        }
        .opportunities {
            padding: 56px 0;
            &-content {
                &::before {
                    top: -40px;
                    left: 55%;
                }
            }
            &-content {
                margin-bottom: 40px;
                padding-left: 0;
                text-align: center;
                p {
                    text-align: left;
                }
            }
        }
        .video-wrapper {
            position: relative;
            z-index: 2;
            max-width: none;
            height: 480px;
        }
        .footer {
            &-logo {
                margin: 0 auto 32px;
            }
        }
    }
    @media (max-width: 575px) {
        .header {
            .container {
                flex-wrap: wrap;
                align-items: flex-end;
            }
            &-logo {
                order: 2;
            }
            &-organization-name {
                order: 1;
                margin-bottom: 8px;
                margin-right: 0;
                padding-left: 0;
                width: 100%;
                font-size: 12px;
                text-align: center;
            }
            .btn {
                order: 3;
            }
        }
        .title {
            font-size: 32px;
        }
        .welcome {
            padding-top: 160px;
        }
        .questions {
            .title {
                margin-bottom: 24px;
            }
        }
    }
    @media (max-width: 380px) {
        .header {
            &-organization-name {
                font-size: 10px;
            }
        }
        .title {
            font-size: 24px;
        }
        .welcome {
            padding-bottom: 16px;
        }
        .opportunities {
            padding: 40px 0;
        }
        .questions {
            padding: 40px 0;
        }
    }
</style>
