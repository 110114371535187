<template>
    <div class="advantages-wrapper">
        <div class="row">
            <Advantage
                    v-for="(advantage, index) in advantages"
                    :key="index"
                    :advantage="advantage"
            ></Advantage>
        </div>
    </div>
</template>

<script>
    import Advantage from '@/components/homePage/Advantage'

    export default {
        name: "AdvantagesWrapper",
        data() {
            return {
                advantages: [
                    {
                        icon: 'ico-briefcase-search-outline-grey.svg',
                        title: 'Здесь есть всё',
                        text: 'На Bytorg собрана информация обо всех государственных и коммерческих тендерах и закупках на территории Беларуси.'
                    },
                    {
                        icon: 'ico-filter-plus-grey.svg',
                        title: 'Умные фильтры',
                        text: 'Создайте любое количество фильтров для поиска нужных закупок. Ищите по ключевым словам, ОКРБ, бюджету и другим параметрам.'
                    },
                    {
                        icon: 'ico-file-document-outline-grey.svg',
                        title: 'Быстрый поиск',
                        text: 'Вам достаточно создать умный фильтр, а не вводить ключевые слова на множестве сайтов.'
                    },
                    {
                        icon: 'ico-bell-ring-outline-grey.svg',
                        title: 'Уведомления о закупках',
                        text: 'Как только появится новая закупка по вашему фильтру, сервис сообщит об этом. Будьте эффективнее конкурентов.'
                    },
                ]
            }
        },
        components: {
            Advantage
        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/bootstrapGrid.less';

    .advantages-wrapper {
        margin-left: auto;
        max-width: 720px;
    }

    .row {
        margin-left: -16px;
        margin-right: -16px;
    }

    @media (max-width: 991px) {
        .advantages-wrapper {
            margin-left: 0;
            max-width: none;
        }
    }
</style>
